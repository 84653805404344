<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      v-if="sortOrder == 'asc'"
      class="h-5 w-5 inline-block text-btnGreen"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M3 4h13M3 8h9m-9 4h6m4 0l4-4m0 0l4 4m-4-4v12"
      />
    </svg>

    <svg
      xmlns="http://www.w3.org/2000/svg"
      v-if="sortOrder == 'desc'"
      class="h-5 w-5 inline-block text-red-500"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M3 4h13M3 8h9m-9 4h9m5-4v12m0 0l-4-4m4 4l4-4"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: ["sortOrder"],
};
</script>

<style scoped></style>
